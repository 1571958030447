import React from "react"

import Box from "../../components/box"
import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import Heading from "../../components/heading"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const NewsletterAnmeldungBestaetigen = () => (
  <Layout backdrop="newsletter">
    <Seo
      title="Anmeldung bestätigen"
      description="Bitte bestätigen Sie ihre Anmeldung"
    />
    <Stack space={[12, 24]}>
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Newsletter",
              link: "/newsletter",
            },
            {
              title: "Anmeldung bestätigen",
              link: "/newsletter/anmeldung-bestaetigen",
            },
          ]}
        />
        <PageTitle>Neues aus dem Hölderlinturm</PageTitle>

        <Constrain>
          <Box bg="muted" p={[6, 12]}>
            <Stack space={8}>
              <Heading as="h2" level={4}>
                Vielen Dank für Ihre Anmeldung!
              </Heading>
              <Paragraph>
                Wir haben Ihnen auch schon die erste E-Mail geschickt und bitten
                Sie, Ihre E-Mail-Adresse über den Aktivierungslink zu
                bestätigen.
              </Paragraph>
              <Paragraph>
                Aus Datenschutzgründen erhalten Sie den Newsletter erst, nachdem
                Sie die Anmeldung explizit bestätigt haben.
              </Paragraph>
            </Stack>
          </Box>
        </Constrain>
      </Stack>
    </Stack>
  </Layout>
)

export default NewsletterAnmeldungBestaetigen
